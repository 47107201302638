import React, { useMemo, useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TableFilter from "../../../components/TableFilter";
import ConfirmationAlert from "../../../components/ConfirmationAlert";
import ProcessDialog from "../dialogs/ProcessDialog";

const deleteUser = async ({ token, deviceId, emailAddress }) => {
  const response = await fetch(
    `${MOBY_API_URL}/api/devicemanager/v1/devices/${deviceId}/removeuser?emailAddress=${emailAddress}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to delete user");
  }
  return response.json();
};

const PanelsListByUser = ({ panelsByUser, selectedUser, token }) => {
  const queryClient = useQueryClient();

  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogUsers, setDialogUsers] = useState([]);
  const [updatedUsers, setUpdatedUsers] = useState([]);

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onMutate: async ({ emailAddress }) => {
      setUpdatedUsers((prev) => [...prev, { emailAddress, status: "pending" }]);
    },
    onSuccess: (_, { emailAddress }) => {
      setUpdatedUsers((prev) =>
        prev.map((user) =>
          user.emailAddress === emailAddress
            ? { ...user, status: "success" }
            : user
        )
      );
    },
    onError: (_, { emailAddress }) => {
      setUpdatedUsers((prev) =>
        prev.map((user) =>
          user.emailAddress === emailAddress
            ? { ...user, status: "error" }
            : user
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["users", token, panelsByUser?.deviceId]);
    },
  });

  const confirmDelete = () => {
    const selectedIndexes = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    const selectedDevices = selectedIndexes.map(
      (index) => panelsByUser[parseInt(index)]
    );

    setDialogUsers((prevDialogUsers) => [
      ...prevDialogUsers,
      ...selectedDevices.map((device) => ({
        ...selectedUser,
        cloudId: device.deviceId,
        status: "pending",
      })),
    ]);
    setShowDialog(true);

    selectedDevices.forEach((device) => {
      deleteUserMutation.mutate({
        token,
        deviceId: device.deviceId,
        emailAddress: selectedUser.email,
      });
    });

    setRowSelection({});
    setShowConfirm(false);
  };

  const deleteAgain = (deviceId) => {
    deleteUserMutation.mutate({
      token,
      deviceId: deviceId,
      emailAddress: selectedUser.email,
    });
  };

  const cancelDelete = () => {
    setShowConfirm(false);
  };

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const columns = useMemo(
    () => [{ accessorKey: "deviceId", header: "Cloud ID" }],
    []
  );

  const table = useReactTable({
    data: panelsByUser || [],
    columns,
    state: {
      globalFilter,
      rowSelection,
    },
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: "auto",
    enableRowSelection: true,
    getRowId: (row) => row.id,
  });

  const handleSelectAll = () => {
    const allRowIds = table.getRowModel().rows.map((row) => row.id);
    const isAllSelected = allRowIds.every((id) => rowSelection[id]);
    if (isAllSelected) {
      setRowSelection({});
    } else {
      const newSelection = {};
      allRowIds.forEach((id) => {
        newSelection[id] = true;
      });
      setRowSelection(newSelection);
    }
  };

  const isAllSelected = useMemo(() => {
    const allRowIds = table.getRowModel().rows.map((row) => row.id);
    return allRowIds.length > 0 && allRowIds.every((id) => rowSelection[id]);
  }, [rowSelection, table]);

  const handleOpenDialog = () => setShowDialog(true);
  const handleCloseDialog = () => setShowDialog(false);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Button
              variant="primary"
              onClick={handleSelectAll}
              className="me-2"
              disabled={panelsByUser.length === 0}
            >
              {isAllSelected ? "Deselect All" : "Select All"}
            </Button>
            <Button
              variant="danger"
              onClick={handleDelete}
              disabled={Object.keys(rowSelection).length === 0}
            >
              Remove Selected Panels
            </Button>
            <Button
              variant="primary"
              onClick={handleOpenDialog}
              className="ms-auto"
            >
              Show Progress
            </Button>
          </div>

          <div className="d-flex align-items-center w-100">
            <div className="flex-grow-1">
              <TableFilter
                filterValue={globalFilter}
                setFilter={setGlobalFilter}
              />
            </div>
          </div>

          <Table bordered hover variant="info" className="mt-2">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => row.toggleSelected()}
                  style={{
                    backgroundColor: row.getIsSelected()
                      ? "lightblue"
                      : "white",
                    cursor: "pointer",
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{ backgroundColor: "inherit", cursor: "pointer" }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>

          <ConfirmationAlert
            text="Are you sure you want to delete the selected panels? This action cannot be undone."
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            show={showConfirm}
            onHide={() => setShowConfirm(false)}
          />

          <ProcessDialog
            show={showDialog}
            onHide={handleCloseDialog}
            infoMessage="Processing selected users and panels."
            users={dialogUsers}
            updatedUsers={updatedUsers}
            deleteAgain={deleteAgain}
            searchBy="user"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PanelsListByUser;
