import { useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';

const DEFAULT_COOKIE_OPTIONS = Object.freeze({
	path: '/',
	domain: window.location.hostname,
	secure: location.protocol === 'https:'
});

export function useOAuthAccessTokenCookie() {
	return useCookie('oauth-access-token');
}

export function useToken() {
	console.log('<useToken>');
	const [oauthAccessToken, setOAuthAccessTokenCookie, removeOAuthAccessTokenCookie] = useOAuthAccessTokenCookie();

	const getToken = () => {
		const oAuthData = (oauthAccessToken) ? oauthAccessToken : { token: null, authenticated: false, user: null, exp: null };
		//Todo : Check expire time
		console.log('getToken!!!' + oAuthData.authenticated);

		return oAuthData
	};

	const [token, setToken] = useState(getToken());

	const saveToken = oAuthData => {
		// console.log("saveToken!!!" + oAuthData.authenticated);

		const cookieExpiresTime = new Date(Date.now() + 2592000);
		const cookieSettings = {
			expires: cookieExpiresTime
		};

		setOAuthAccessTokenCookie(oAuthData, cookieSettings);
		// console.log("<<saveToken!!!>>" + oAuthData.token);
		setToken(oAuthData);
	};

	const resetToken = () => {
		removeOAuthAccessTokenCookie();
		// Directly put initial object to immediately reflct change
		setToken({ token: null, authenticated: false, user: null, exp: null });
	};

	const isLoggedIn = () => {
		var isLoggedIn = false;
		const current = new Date().getTime()
		const oAuthData = (oauthAccessToken) ? oauthAccessToken : { token: null, authenticated: false, user: null, exp: null };
		
		//Todo : Check expire time
		if (oAuthData && current > oAuthData.exp * 1000) {
			console.log('Token Expired => ', current, oAuthData.exp);
			isLoggedIn = false;
		}
		else {
			console.log('Token Valid => ', oAuthData.authenticated, oAuthData.exp);
			isLoggedIn = true;
		}

		return isLoggedIn
	};

	return {
		setToken: saveToken,
		deleteToken: resetToken,
		token,
		isLoggedIn
	}
}

export function useCookie(cookieName) {
	const [cookies, originalSetCookies, originalRemoveCookies] = useCookies([cookieName]);

	const setCookie = useCallback((value, customOptions) => {
		const options = {
			...DEFAULT_COOKIE_OPTIONS,
			...customOptions
		};

		originalSetCookies(cookieName, value, options);
		console.log('originalSetCookies: ' + cookieName);
	}, [originalSetCookies, cookieName]);

	const removeCookie = useCallback(customOptions => {
		const options = {
			...DEFAULT_COOKIE_OPTIONS,
			...customOptions
		};

		originalRemoveCookies(cookieName, options);
		console.log('originalRemoveCookies: ' + cookieName);
	}, [originalRemoveCookies, cookieName]);

	const cookieValue = cookies[cookieName];

	return [cookieValue, setCookie, removeCookie];
}

