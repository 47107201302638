import React, { useMemo, useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Button,
} from "react-bootstrap";
import ConfirmationAlert from "../../../components/ConfirmationAlert";

const ProcessDialog = ({
  show,
  onHide,
  infoMessage,
  users,
  updatedUsers,
  deleteAgain,
  searchBy,
}) => {
  const [userData, setUserData] = useState(users);
  const [showConfirmResend, setShowConfirmResend] = useState(false);

  useEffect(() => {
    setUserData(users);
  }, [users]);

  useEffect(() => {
    setUserData((prevData) =>
      prevData.map((user) => {
        const updatedUser = updatedUsers.find(
          (u) => u.emailAddress === user.email
        );
        return updatedUser ? { ...user, status: updatedUser.status } : user;
      })
    );
  }, [updatedUsers]);

  const renderStatus = (status) => {
    if (status === "pending")
      return <span className="text-warning">Pending</span>;
    if (status === "success")
      return <span className="text-success">Success</span>;
    if (status === "error") return <span className="text-danger">Error</span>;
    return <span>{status}</span>;
  };

  const columns = useMemo(
    () => [
      { accessorKey: "firstName", header: "First Name" },
      { accessorKey: "lastName", header: "Last Name" },
      { accessorKey: "email", header: "Email" },
      { accessorKey: "cloudId", header: "Cloud ID" },
      {
        id: "status",
        header: "Status",
        cell: ({ row }) => renderStatus(row.original.status),
      },
    ],
    []
  );

  const table = useReactTable({
    data: userData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRemoveSuccess = () => {
    setUserData((prevData) =>
      prevData.filter((user) => user.status !== "success")
    );
  };

  const handleDeleteAgain = () => {
    setShowConfirmResend(true);
  };

  const confirmResendInvitations = () => {
    setShowConfirmResend(false);

    userData.forEach((user) => {
      if (user.status === "error") {
        deleteAgain(searchBy === "panel" ? user.email : user.cloudId);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Process Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              {infoMessage && <Alert variant="info">{infoMessage}</Alert>}
              <Table bordered variant="info" className="align-middle">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="bg-white">
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} style={{ backgroundColor: "white" }}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="danger"
                onClick={handleRemoveSuccess}
                className="me-2"
              >
                Remove Success
              </Button>
              <Button variant="primary" onClick={handleDeleteAgain}>
                Delete Users again
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <ConfirmationAlert
        text="Are you sure you want to resend invitations for failed entries?"
        onConfirm={confirmResendInvitations}
        onCancel={() => setShowConfirmResend(false)}
        show={showConfirmResend}
        onHide={() => setShowConfirmResend(false)}
      />
    </Modal>
  );
};

export default ProcessDialog;
