import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import {
	TranslatedString
} from '@bosch/bt-ui';

export default function withErrorHandler(WrappedComponent) {
	class ErrorHandler extends React.PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				encounteredErrorDuringRendering: false
			};
		}

		componentDidCatch(error, info) {
			this.setState({ encounteredErrorDuringRendering: true });
		}

		render() {
			const {
				encounteredErrorDuringRendering
			} = this.state;

			return encounteredErrorDuringRendering ? <TranslatedString stringId="ErrorDuringRendering" /> : <WrappedComponent {...this.props} />
		}
	}

	hoistNonReactStatics(ErrorHandler, WrappedComponent);

	return ErrorHandler;
}