import React, { useMemo } from "react";
import { Footer, TranslatedString } from "@bosch/bt-ui";
import { Container, Row, Col } from "react-bootstrap";
import withErrorHandler from "./ErrorHandler";

const MainFooter = () => {
  const shouldShowVersionInFooter = false;

  const footerCopyrightSection = useMemo(() => {
    let myVersion = null;

    if (shouldShowVersionInFooter) {
      myVersion = {
        label: <TranslatedString stringId="Version" />,
        text: "insert app version here",
      };
    }

    return {
      copyrightsText: `© Bosch Security Systems, LLC`,
      version: myVersion,
    };
  }, [shouldShowVersionInFooter]);

  const environment = MOBY_ENV || "prod";

  const links = {
    termsOfUse:
      environment === "test"
        ? "https://rb-s-st-serviceportal.bosch.com/us/termsAndConditions"
        : "https://serviceportal.boschsecurity.com/us/termsAndConditions",
    privacyNotice:
      environment === "test"
        ? "https://rb-s-st-serviceportal.bosch.com/us/privacyPolicy"
        : "https://serviceportal.boschsecurity.com/us/privacyPolicy",
    corporateInfo:
      environment === "test"
        ? "https://rb-s-st-serviceportal.bosch.com/us/imprint"
        : "https://serviceportal.boschsecurity.com/us/imprint",
    webAccessibility:
      environment === "test"
        ? "https://www.bosch.us/notices/statement-of-ada-compilance.html"
        : "https://www.bosch.us/notices/statement-of-ada-compilance.html",
  };

  return (
    <footer className="bg-light text-center text-lg-start">
      <Container fluid className="p-3">
        <Row>
          <Col>
            <p className="mb-1">{footerCopyrightSection.copyrightsText}</p>
            <p className="mb-1">
              <a
                href={links.termsOfUse}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>{" "}
              |{" "}
              <a
                href={links.privacyNotice}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Notice
              </a>{" "}
              |{" "}
              <a
                href={links.corporateInfo}
                target="_blank"
                rel="noopener noreferrer"
              >
                Corporate Information
              </a>{" "}
              |{" "}
              <a
                href={links.webAccessibility}
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Accessibility
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default withErrorHandler(React.memo(MainFooter));
