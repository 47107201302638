import React, { useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useAddNewStore } from "../../../store/add_new_store";
import ConfirmationAlert from "../../../components/ConfirmationAlert";
import AddNewUserDialog from "../dialogs/AddNewUserDialog";
// import TableFilter from "../../../components/TableFilter";
import { FaTrashAlt } from "react-icons/fa";

const UsersList = () => {
  const { setUsers } = useAddNewStore();
  const [rowSelection, setRowSelection] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    setUsers(data);
  }, [data, setUsers]);

  const columns = React.useMemo(
    () => [
      { accessorKey: "firstName", header: "First Name" },
      { accessorKey: "lastName", header: "Last Name" },
      { accessorKey: "email", header: "Email" },
      {
        id: "actions",
        header: "",
        cell: ({ row }) => (
          <Button
            variant="link"
            onClick={() => handleRemoveClick(row.original.id)}
            style={{
              padding: 0,
              margin: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaTrashAlt style={{ color: "red", margin: 0, padding: 0 }} />
          </Button>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: { globalFilter, rowSelection },
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: "auto",
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: (row) => row.id,
  });

  const handleRemoveClick = (id) => {
    setUserToRemove(id);
    setShowConfirm(true);
  };

  const confirmRemoveSelected = () => {
    const updatedUsers = data.filter((user) => user.id !== userToRemove);
    setData(updatedUsers);
    setUserToRemove(null);
    setShowConfirm(false);
  };

  const cancelRemoveSelected = () => {
    setUserToRemove(null);
    setShowConfirm(false);
  };

  const handleShowAddUserDialog = () => setShowAddUserDialog(true);
  const handleCloseAddUserDialog = () => setShowAddUserDialog(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleAddNewUser = () => {
    const newUserData = {
      id: (data.length + 1).toString(),
      ...newUser,
    };
    setData([...data, newUserData]);
    setNewUser({ firstName: "", lastName: "", email: "" });
    handleCloseAddUserDialog();
  };

  return (
    <>
      <Row className="mb-2">
        <Col className="d-flex">
          {/* <div className="flex-grow-1 me-3">
            <TableFilter
              filterValue={globalFilter}
              setFilter={setGlobalFilter}
            />
          </div> */}
          <Button variant="success" onClick={handleShowAddUserDialog}>
            Add New User
          </Button>
        </Col>
      </Row>

      <Table bordered variant="info">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="align-middle"
                  style={{ backgroundColor: "white" }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <AddNewUserDialog
        show={showAddUserDialog}
        onHide={handleCloseAddUserDialog}
        newUser={newUser}
        handleInputChange={handleInputChange}
        handleAddNewUser={handleAddNewUser}
      />

      <ConfirmationAlert
        text="Are you sure you want to remove the selected user?"
        onConfirm={confirmRemoveSelected}
        onCancel={cancelRemoveSelected}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
      />
    </>
  );
};

export default UsersList;
