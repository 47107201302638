import React from 'react';
import { Form } from 'react-bootstrap';

const TableFilter = ({ filterValue, setFilter }) => {
    return (
        <Form className="d-flex">
            <Form.Control
                type="text"
                placeholder="Search..."
                value={filterValue || ''}
                onChange={(e) => setFilter(e.target.value)}
            />
        </Form>
    );
}

export default TableFilter;
