import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationAlert = ({
    title = 'Confirmation',
    text,
    onConfirm,
    onCancel,
    show,
    onHide,
}) => {
    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        } else if (onHide) {
            onHide();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else if (onHide) {
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={handleCancel} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmationAlert.defaultProps = {
    onConfirm: null,
    onCancel: null,
    show: false,
    onHide: null,
};

export default ConfirmationAlert;
