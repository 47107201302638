import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Badge,
  Button,
} from "react-bootstrap";
import ConfirmationAlert from "../../../components/ConfirmationAlert";

const ProcessDialog = ({
  show,
  onHide,
  infoMessage,
  data,
  updatedUsers,
  inviteMutation,
  selectedPanels,
}) => {
  const [userData, setUserData] = useState([]);
  const [showConfirmResend, setShowConfirmResend] = useState(false);

  useEffect(() => {
    setUserData((prevData) => {
      const updatedData = [...prevData];

      data.forEach((newUser) => {
        const existingUser = updatedData.find(
          (user) => user.email === newUser.email
        );

        if (existingUser) {
          Object.keys(newUser.status).forEach((cloudId) => {
            if (!existingUser.status[cloudId]) {
              existingUser.status[cloudId] = newUser.status[cloudId];
            }
          });
        } else {
          updatedData.push(newUser);
        }
      });

      return updatedData;
    });
  }, [data]);

  useEffect(() => {
    setUserData((prevData) =>
      prevData.map((user) => {
        const updatedUser = updatedUsers.find(
          (update) => update.email === user.email
        );

        if (updatedUser) {
          const updatedStatus = { ...user.status };

          Object.keys(updatedUser.status).forEach((cloudId) => {
            if (updatedStatus[cloudId]) {
              updatedStatus[cloudId] = {
                ...updatedStatus[cloudId],
                status: updatedUser.status[cloudId].status,
              };
            }
          });

          return {
            ...user,
            status: updatedStatus,
          };
        }

        return user;
      })
    );
  }, [updatedUsers]);

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return <Badge bg="warning">Pending</Badge>;
      case "success":
        return <Badge bg="success">Success</Badge>;
      case "error":
        return <Badge bg="danger">Error</Badge>;
      default:
        return <Badge bg="secondary">Unknown</Badge>;
    }
  };

  const handleRemoveSuccess = () => {
    setUserData((prevData) =>
      prevData
        .map((user) => {
          const updatedStatus = Object.fromEntries(
            Object.entries(user.status).filter(
              ([, panelStatus]) => panelStatus.status !== "success"
            )
          );

          return Object.keys(updatedStatus).length > 0
            ? { ...user, status: updatedStatus }
            : null;
        })
        .filter((user) => user !== null)
    );
  };

  const handleResendInvitations = () => {
    setShowConfirmResend(true);
  };

  const confirmResendInvitations = () => {
    setShowConfirmResend(false);
    console.log("Resend Invitations clicked");
    console.log("userData", userData);

    userData.forEach((user) => {
      Object.entries(user.status).forEach(([cloudId, panelStatus]) => {
        if (panelStatus.status === "error") {
          inviteMutation.mutate({
            deviceId: selectedPanels.find((panel) => panel.cloudId === cloudId)
              ?.deviceId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            cloudId,
          });
        }
      });
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Process Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              {infoMessage && <Alert variant="info">{infoMessage}</Alert>}
              <Table bordered className="align-middle" variant="info">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {userData.length > 0 ? (
                    userData.map((user, idx) => (
                      <tr key={idx}>
                        <td style={{ backgroundColor: "white" }}>
                          {user.firstName}
                        </td>
                        <td style={{ backgroundColor: "white" }}>
                          {user.lastName}
                        </td>
                        <td style={{ backgroundColor: "white" }}>
                          {user.email}
                        </td>
                        <td style={{ backgroundColor: "white" }}>
                          <ul className="list-unstyled">
                            {Object.values(user.status).map(
                              ({ name, status }, index) => (
                                <li key={index}>
                                  <strong>{name}:</strong>{" "}
                                  {getStatusBadge(status)}
                                </li>
                              )
                            )}
                          </ul>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        className="text-center"
                        style={{ backgroundColor: "white" }}
                      >
                        No user is in progress
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="danger"
                onClick={handleRemoveSuccess}
                className="me-2"
              >
                Remove Success
              </Button>
              <Button variant="primary" onClick={handleResendInvitations}>
                Resend Invitations
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      {/* 確認彈窗 */}
      <ConfirmationAlert
        text="Are you sure you want to resend invitations for failed entries?"
        onConfirm={confirmResendInvitations}
        onCancel={() => setShowConfirmResend(false)}
        show={showConfirmResend}
        onHide={() => setShowConfirmResend(false)}
      />
    </Modal>
  );
};

export default ProcessDialog;
