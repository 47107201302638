import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';

import {
	TranslatedString
} from '@bosch/bt-ui';

import './MainAppBar.css';
import withErrorHandler from './ErrorHandler';
import LogOut from './LogOut.jsx';
import { AppHeader } from './AppHeader.jsx';

function getUserInfoForProfileAccess(userInfo) {
	return {
		loggedInUsername: <TranslatedString stringId={userInfo.info} />,
		name: userInfo.name,
		monogram: null,
		email: userInfo.email
	};
}

const MainAppBar = (props) => {
	const {
		token,
		isDirty,
		pageTitle
	} = props;

	const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
	const [isLoggingOut, setIsLoggingOut] = useState(false);

	const logOut = useCallback(() => {
		if (!isDirty) {
			setIsAccountMenuOpen(false);
			setIsLoggingOut(true);
		}
	}, [isDirty]);

	const toMyProfile = useCallback(() => {
		//TODO: need to update to singlekeyid's url
		window.location.href = 'https://google.com'
	})

	console.log('<MainAppBar>');
	const isLoggedIn = (token && token.authenticated) ? true : false;

	return (
		<React.Fragment>
			{
				isLoggingOut && <LogOut />
			}
			<AppHeader
				pageTitle={pageTitle}
				profileAccess={
					isLoggedIn ?
						{
							isLoggedIn: true,
							user: getUserInfoForProfileAccess(token.user),
							logoutLink: {
								text: <TranslatedString stringId="Logout" />,
								onClick: logOut
							},
							profileLink: {
								text: <TranslatedString stringId="My Profile" />,
								onClick: toMyProfile
							},
							token
						}
						:
						{
							isLoggedIn: false,
							loginLink: {
								text: <TranslatedString stringId="Login" />,
								href: '/login'
							}
						}
				}
			/>
		</React.Fragment>
	);
}

export default withErrorHandler(React.memo(MainAppBar));