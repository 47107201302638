import React, { useState } from "react";
import { Container, Row, Col, Accordion, Button, Alert } from "react-bootstrap";
import PanelsList from "./lists/PanelsList";
import UsersList from "./lists/UsersList";
import SelectedPanels from "./lists/SelectedPanels";
import ProcessDialog from "./dialogs/ProcessDialog";
import { useAddNewStore } from "../../store/add_new_store";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import { useMutation } from "@tanstack/react-query";
import "./AddNewPage.css";

const inviteUser = async ({
  deviceId,
  email,
  firstName,
  lastName,
  panelName,
}) => {
  const response = await fetch(
    `${MOBY_API_URL}/api/devicemanager/v1/devices/${deviceId}/inviteuser`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Email: email,
        FirstName: firstName,
        LastName: lastName,
      }),
    }
  );
  if (!response.ok) throw new Error("Failed to invite user");
  return response.json();
};

const AddNewPage = (token) => {
  const [showProcessDialog, setShowProcessDialog] = useState(false);
  const [showConfirmInvite, setShowConfirmInvite] = useState(false);
  const [processedUsers, setProcessedUsers] = useState([]);
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const { selectedPanels, users } = useAddNewStore();

  const inviteMutation = useMutation({
    mutationFn: inviteUser,
    onMutate: async ({ email, cloudId }) => {
      setUpdatedUsers((prev) => {
        const existingUser = prev.find((user) => user.email === email);

        if (existingUser) {
          return prev.map((user) =>
            user.email === email
              ? {
                  ...user,
                  status: {
                    ...user.status,
                    [cloudId]: { name: cloudId, status: "pending" },
                  },
                }
              : user
          );
        } else {
          return [
            ...prev,
            {
              email,
              status: {
                [cloudId]: { name: cloudId, status: "pending" },
              },
            },
          ];
        }
      });
    },
    onSuccess: (data, variables) => {
      setUpdatedUsers((prev) =>
        prev.map((user) => {
          if (user.email === variables.email) {
            return {
              ...user,
              status: {
                ...user.status,
                [variables.cloudId]: {
                  ...user.status[variables.cloudId],
                  status: "success",
                },
              },
            };
          }
          return user;
        })
      );
    },
    onError: (error, variables) => {
      setUpdatedUsers((prev) =>
        prev.map((user) => {
          if (user.email === variables.email) {
            return {
              ...user,
              status: {
                ...user.status,
                [variables.cloudId]: {
                  ...user.status[variables.cloudId],
                  status: "error",
                },
              },
            };
          }
          return user;
        })
      );
    },
  });

  const initializeUsersData = () => {
    setProcessedUsers((prevData) => {
      const updatedData = [...prevData];

      users.forEach((user) => {
        const existingUser = updatedData.find((u) => u.email === user.email);

        if (existingUser) {
          selectedPanels.forEach((panel) => {
            if (!existingUser.status[panel.cloudId]) {
              existingUser.status[panel.cloudId] = {
                name: panel.cloudId,
                status: "pending",
              };
            }
          });
        } else {
          const newUser = {
            ...user,
            status: selectedPanels.reduce((acc, panel) => {
              acc[panel.cloudId] = {
                name: panel.cloudId,
                status: "pending",
              };
              return acc;
            }, {}),
          };
          updatedData.push(newUser);
        }
      });

      return updatedData;
    });
  };

  const handleInvite = () => {
    setShowConfirmInvite(true);
  };

  const confirmInvite = () => {
    setShowConfirmInvite(false);
    setShowProcessDialog(true);
    initializeUsersData();
    setUpdatedUsers([]); // Reset updatedUsers when a new invite starts

    for (const user of users) {
      for (const panel of selectedPanels) {
        inviteMutation.mutate({
          deviceId: panel.deviceId,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          cloudId: panel.cloudId,
        });
      }
    }
  };

  return (
    <Container fluid>
      <Row className="mt-3">
        <Alert variant="info">
          Add the panels on the left to which you'd like to invite new users.
          Then, enter the usernames and emails on the right for the users you'd
          like to invite to the added panels.
        </Alert>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Selected ({selectedPanels.length}) Panels and Invite (
                {users.length}) Users
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <SelectedPanels />
                  </Col>
                  <Col md={6}>
                    <UsersList />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="text-end">
          <Button variant="primary" onClick={handleInvite} className="me-3">
            Begin Invites
          </Button>
          <Button variant="primary" onClick={() => setShowProcessDialog(true)}>
            Show Progress
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12}>
          <PanelsList token={token} />
        </Col>
      </Row>

      <ConfirmationAlert
        text="Are you sure you want to invite the selected users to the selected panels?"
        onConfirm={confirmInvite}
        onCancel={() => setShowConfirmInvite(false)}
        show={showConfirmInvite}
        onHide={() => setShowConfirmInvite(false)}
      />

      <ProcessDialog
        show={showProcessDialog}
        onHide={() => setShowProcessDialog(false)}
        infoMessage="Processing selected users and panels."
        data={processedUsers}
        updatedUsers={updatedUsers}
        inviteMutation={inviteMutation}
        selectedPanels={selectedPanels}
      />
    </Container>
  );
};

export default AddNewPage;
