import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';

import {
	LoaderIndicator
} from '@bosch/bt-ui';

import './CenterLoadingContainer.css';
import withErrorHandler from './ErrorHandler';

const CenterLoadingContainer = (props) => {
	return (
		<div className="center-loading-container">
			<LoaderIndicator />
		</div>
	);
}

export default withErrorHandler(CenterLoadingContainer);