import React from 'react';

import {
	Main,
	FullLayout
} from '@bosch/bt-ui';

import './LoginPageLayout.css';
import MainFooter from '../components/MainFooter';
import withErrorHandler from '../components/ErrorHandler';
import MainAppBar from '../components/MainAppBar';

const LoginPageLayout = (props) => {
	const {
		children
	} = props;

	return (
		<FullLayout>
			<MainAppBar pageTitle="Moby" />

			<Main>
				{children}
			</Main>

			<MainFooter />
		</FullLayout>
	);
}

export default withErrorHandler(LoginPageLayout);
