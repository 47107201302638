import { useUnauthenticatedLanguage, useCookie } from './Util';

export function useOAuthAccessTokenCookie() {
    return useCookie('oauth-access-token');
}
export function useOAuthAccessTokenDurationCookie() {
    return useCookie('oauth-access-token-duration');
}
export function useOAuthRefreshTokenCookie() {
    return useCookie('oauth-refresh-token');
}
export function useOAuthRefreshTokenDurationCookie() {
    return useCookie('oauth-refresh-token-duration');
}
export function useRememberMeCookie() {
    return useCookie('remember-me');
}
export function useIsLoggedIn() {
    const [oauthAccessToken, setOAuthAccessTokenCookie, removeOAuthAccessTokenCookie] = useOAuthAccessTokenCookie();
    return oauthAccessToken != null;
	// return true;
}
export function useCurrentLanguage() {
	const userConfig = {}; // TODO: Need to get your user config to here.

	const currentLanguage = 'en-US';
	// const authenticatedLanguage = useAuthenticatedLanguage(userConfig);
	// const unauthenticatedLanguage = useUnauthenticatedLanguage();

	// const isLoggedIn = useIsLoggedIn();

	// const currentLanguage = isLoggedIn ?
	// 	authenticatedLanguage
	// 	:
	// 	unauthenticatedLanguage;

	// if (!currentLanguage) {
	// 	//console.error('Failed to determine requested language. Defaulting to English.');

	// 	return 'en-US';
	// }

	return currentLanguage;
}
function useAuthenticatedLanguage(userConfig) {
	if (!userConfig)
		return null;

	const currentUser = userConfig.currentUser;

	if (!currentUser)
		return null;

	return currentUser.language;
}