import React, { useState, useEffect, useCallback } from 'react';

import {
	TranslatedString,
	PageNotFound
} from '@bosch/bt-ui';

import withErrorHandler from '../components/ErrorHandler';
import DefaultLayout from '../layouts/DefaultLayout';

const PageNotFoundPage = (props) => {
	return (
		<DefaultLayout>
			<PageNotFound
				pageNotFoundMessage={<TranslatedString stringId="The page you are looking for is not available." />}
				backHomeButtonConfig={{
					url: '/',
					text: <TranslatedString stringId="Back home" />
				}}
			/>
		</DefaultLayout>
	);
};

export default withErrorHandler(React.memo(PageNotFoundPage));