import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';

import '@bosch/bt-ui/bosch-style-guide.css';
import '../css/main.css';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppCore from './core/AppCore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({})

export default class App extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			encounteredErrorDuringRendering: false
		};

	}
	componentDidCatch(error, info) {
		this.setState({
			encounteredErrorDuringRendering: true
		});
	}
	render() {
		const {
			encounteredErrorDuringRendering
		} = this.state;

		if (encounteredErrorDuringRendering)
			return <span>Error while rendering app</span>;

		return (
			<React.StrictMode>
				<QueryClientProvider client={queryClient}>
					<AppCore />
				</QueryClientProvider>
			</React.StrictMode>
		);
	}
}

const root = createRoot(document.getElementById('app-root'));
root.render(<App />);