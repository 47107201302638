import React from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import withErrorHandler from './ErrorHandler';

function Prompt(props) {
    const {
        when,
        message
    } = props;
    
    useBlocker(() => {
        if (when) {
            return !window.confirm(message)
        }
        return false
    })

  return (
    <div key={when}/>
  )
}

export default withErrorHandler(Prompt);