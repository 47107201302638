import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import {
	Main,
	SideNavigation,
	SideNavigationMenuItems,
	FullLayout,
	getTranslatedString,
	useTranslations
} from '@bosch/bt-ui';

import './DefaultLayout.css';
import MainAppBar from '../components/MainAppBar';
import MainFooter from '../components/MainFooter';
import withErrorHandler from '../components/ErrorHandler';
import HomeIcon from '../../icons/home.svg';
import SimIcon from '../../icons/simcard.svg';
import CompanyIcon from '../../icons/company.svg';
import CloudShareIcon from '../../icons/cloud-share.svg';
import ControlPanelIcon from '../../icons/control-panel.svg';
import PromptUnloading from '../components/PromptUnloading';
import UserServiceIcon from '../../icons/user-service.svg';


const DefaultLayoutContext = React.createContext();

export const DefaultLayoutContextProvider = DefaultLayoutContext.Provider;

export function useDefaultLayout() {
	return useContext(DefaultLayoutContext);
}

const DefaultLayout = (props) => {
	const {
		token,
		activeMenuId,
		pageTitle,
		isDirty,
		setIsDirty,
		children
	} = props;

	const location = useLocation();

	const translations = useTranslations();
	const {
		isSideNavigationOpen,
		setIsSideNavigationOpen
	} = useDefaultLayout();

	const menus = [
		{
			key: 'home',
			text: getTranslatedString('Home', translations),
			url: '/',
			icon: HomeIcon
		},
	];

	useEffect(() => {
		// Everytime refresh DefaultLayout then reset dirty page
		setIsDirty(false);
	}, [setIsDirty, location.pathname]);

	return (
		<FullLayout
		>
			<PromptUnloading isDirty={isDirty} />
			<MainAppBar token={token} isDirty={isDirty} pageTitle={pageTitle} />

			<Main>
				{children}
			</Main>

			<MainFooter />
		</FullLayout>
	);
}

export default withErrorHandler(DefaultLayout);