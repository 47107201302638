import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { jwtDecode } from "jwt-decode";
import {
	getTranslatedString
} from '@bosch/bt-ui';

export const USERNAME_MIN_LENGTH = 6;
export const USERNAME_MAX_LENGTH = 64;
export const USERNAME_VALIDATION_REGEX = '[A-Za-z0-9_.@]+';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 15;
//** Regex modified, but originally from https://stackoverflow.com/a/19605207
export const PASSWORD_VALIDATION_REGEX = '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{' + PASSWORD_MIN_LENGTH + ',}';

export const DEFAULT_LANGUAGE_ID = 50;
export const MILLISECONDS_IN_MINUTE = 1000 * 60;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;

const DEFAULT_COOKIE_OPTIONS = Object.freeze({
	path: '/',
	secure: location.protocol === 'https:'
});

export const LANGUAGES = Object.freeze([
	{
		id: 50,
		nativeName: 'English',
		culture: 'en-US',
		translationStringId: 'English'
	}
]);
export function getLanguageOptions() {
	return LANGUAGES.map(language => {
		return {
			label: getTranslatedString(language.translationStringId),
			value: language.culture
		};
	});
}
export function getLanguageOptionsWithBlankDefault() {
	return [
		{
			label: getTranslatedString('PleaseSelect'),
			value: ''
		},
		...LANGUAGE_DROPDOWN_OPTIONS
	];
}
export function useUnauthenticatedLanguage() {
	const [unauthenticatedLanguageCookieValue, setUnauthenticatedLanguage, removeUnauthenticatedLanguage] = useUnauthenticatedLanguageCookie();

	return unauthenticatedLanguageCookieValue || getBrowserLanguage();
}
function getBrowserLanguage() {
	const browserLanguages = window.navigator.languages;

	for (let browserLanguage of browserLanguages) {
		const supportedLanguage = LANGUAGES.find(language => language.culture === browserLanguage);

		if (supportedLanguage != null)
			return supportedLanguage.culture;
	}

	return null;
}
export function setLanguageByCulture(culture, setUnauthenticatedLanguageCookieFunc) {
	const expiresDate = addMillisecondsToDate(new Date(), 10 * MILLISECONDS_IN_DAY);

	setUnauthenticatedLanguageCookieFunc(culture, {
		...getDefaultCookieSettings(),
		expires: expiresDate
	});
}
export function getDefaultCookieSettings() {
	//** From https://stackoverflow.com/a/282454
	const isHttps = window.location.protocol === 'https:';

	return {
		domain: window.location.hostname,
		secure: isHttps
	};
}
// This function is from https://stackoverflow.com/a/7688011/14239942
export function addMillisecondsToDate(date, milliseconds) {
	return new Date(date.getTime() + milliseconds);
}
export function useCookie(cookieName) {
	const [cookies, originalSetCookies, originalRemoveCookies] = useCookies([cookieName]);

	const setCookie = useCallback((value, customOptions) => {
		const options = {
			...DEFAULT_COOKIE_OPTIONS,
			...customOptions
		};

		originalSetCookies(cookieName, value, options);
	}, [originalSetCookies, cookieName]);

	const removeCookie = useCallback(customOptions => {
		const options = {
			...DEFAULT_COOKIE_OPTIONS,
			...customOptions
		};

		originalRemoveCookies(cookieName, options);
	}, [originalRemoveCookies, cookieName]);

	const cookieValue = cookies[cookieName];

	return [cookieValue, setCookie, removeCookie];
}
export function useUnauthenticatedLanguageCookie() {
	return useCookie('UnauthenticatedLanguage');
}
export function getUsernameFieldTitle() {
	return getTranslatedString('UsernameRequirements');
}
export function getPasswordFieldTitle() {
	return getTranslatedString('PasswordRequirements');
}

 export function  prepareLogData(token,activity, message) {
	if(token!=null)
	{
		const jwtstring = typeof token === 'string' ? token : token.token;
		const decoded = jwtDecode(jwtstring);
		
		const jti = decoded.jti;
		const email = decoded.email;
		const data = {
			'SessionId': jti,
			'Email': email,
			'Activity': activity,
			'ActionMessage': message,
			'Detailinfo': ""
		};

		return data;
	}
}