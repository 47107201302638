import Keycloak from 'keycloak-js';
import DevKeycloakConfig from '../../keycloak.dev.json';
import TestKeycloakConfig from '../../keycloak.test.json';
import ProdKeycloakConfig from '../../keycloak.prod.json';

export function getKeycloak() {
    let KeycloakConfig;

    // Select the correct Keycloak configuration file based on the environment variables.
    if (MOBY_ENV === 'prod') {
        KeycloakConfig = ProdKeycloakConfig;
    }
    else if (MOBY_ENV === 'test') {
        KeycloakConfig = TestKeycloakConfig;
    } 
    else {
        KeycloakConfig = DevKeycloakConfig;
    }

    const keycloak = new Keycloak(KeycloakConfig);
    return keycloak;
}