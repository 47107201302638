import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';

import './AppHeader.css';
import { ProfileAccess } from './ProfileAccess';
import BoschLogoIcon from '../../icons/Bosch-logo.svg';

import {
	Icon,
} from '@bosch/bt-ui';

export const AppHeader = React.memo((props) => {
	const {
		pageTitle,
		brandLink,
		profileAccess
	} = props;

	const logo = (
		<Icon
			icon={BoschLogoIcon}
			width={null}
			height={null}
		/>
	);

	return (
		<header className="O-Header">
			<div className="header-contents">
				<h1 className="page-name">{pageTitle}</h1>

				{
					profileAccess &&
					<ProfileAccess {...profileAccess} />
				}

				<div className="logo-container">
					{
						brandLink ?
							<a className="O-Header__brandLogo" href={brandLink} aria-label="Logo" >
								{logo}
							</a>
							:
							logo
					}
				</div>
			</div>
		</header>
	);
});

AppHeader.displayName = 'AppHeader';
AppHeader.propTypes = {
	brandLink: PropTypes.string,
	profileAccess: PropTypes.object,
	pageTitle: PropTypes.node
}