import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import querystring from 'querystring';
import withErrorHandler from '../components/ErrorHandler';

const LoggedInPages = (props) => {
	const {
		token,
		deleteToken,
		isLoggedIn,
		children,
	} = props;

	const location = useLocation();
	const loginParameters = querystring.stringify({
		'return-url': location.pathname + location.search
	});

	console.log('LoggedInPages.authenticated:', loginParameters);

	// Case 1 : Didn't login and direct click function menu link => isLoggedIn() is false, and token.token is null
	// Case 2 : Click function menu link and token expired => isLoggedIn() is false, and token.token is not null
	if (!isLoggedIn()) {
		if (token.token) {
			deleteToken()
		}
		return <Navigate to={'./login?' + loginParameters} replace />
	}

	return children;
};

export default withErrorHandler(React.memo(LoggedInPages));