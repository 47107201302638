import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';

import './PromptUnloading.css';
import withErrorHandler from './ErrorHandler';
import { Beforeunload } from 'react-beforeunload';
import Prompt from './Prompt';

const PromptUnloading = (props) => {
	const {
		isDirty
    } = props;

	return (
		<div id="prompt-unloading">
		{
			isDirty && (
				<Beforeunload onBeforeunload={(event) => event.preventDefault()}>
				<Prompt when={isDirty} message="Changes you made may not be saved." />
				</Beforeunload>
			)
		} 
		</div>
	);
}

export default withErrorHandler(PromptUnloading);